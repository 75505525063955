import React, { useState } from 'react';

import { Button, Card, Form, Carousel, Table, Jumbotron } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import Axios from 'axios';

const retrievalUrl = 'https://7jnb9f7vph.execute-api.us-west-2.amazonaws.com/default/listing-retrieval-service';

function App() {
  const [ certification, setCertification ] = useState<any>(undefined);
  const [ noResults, setNoResults ] = useState<boolean>(false);
  const [ certificationNumber, setCertificationNumber ] = useState<string>('');

  const handleSubmit = async () => {
    const result = await Axios.get(retrievalUrl + '?certificationNumber=' + certificationNumber);
    
    if (result.data && result.data.Items && result.data.Items.length > 0) {
      setNoResults(false);
      setCertification(result.data.Items[0]);
    } else {
      setCertification(undefined);
      setNoResults(true);
    }
  }

  const renderCertification = () => {
    if (certification) {
      return (
        <Card style={{ width: '100%' }}>
          <Card.Body>
            <Carousel style={{ maxWidth: '500px', width: '100%', margin: '0 auto'}}>
              {
                certification.resizedImageUrls.map((imageUrl: string) => (
                  <Carousel.Item>
                    <img className="d-block w-100" src={imageUrl} />
                  </Carousel.Item>
                ))
              }
            </Carousel>

            <Card.Title>{certification.description}</Card.Title>
            <Table striped={true} style={{fontSize: 12}}>
              <tbody>
                <tr>
                  <td>Date</td>
                  <td style={{ textAlign: 'right' }}>{(new Date(certification.createDate)).toDateString()}</td>
                </tr>
                <tr>
                  <td>Cert #</td>
                  <td style={{ textAlign: 'right' }}>{certification.certificationNumber}</td>
                </tr>
                <tr>
                  <td>Field</td>
                  <td style={{ textAlign: 'right' }}>{certification.field}</td>
                </tr>
                <tr>
                  <td>Signatures</td>
                  <td style={{ textAlign: 'right' }}>{certification.numberOfSignatures}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      );
    }
  }

  return (
    <div className="App">
      <div style={{ textAlign: 'center' }}>
        <img src="logo.png" style={{ maxWidth: '500px', width: '100%' }} />
      </div>

      <Jumbotron style={{ textAlign: 'center'}}>
        <Form>
          <Form.Group controlId="certificationNumberInput">
            <Form.Control 
              style={{ alignContent: 'center'}}
              type="text" 
              placeholder="Enter certification number" 
              value={certificationNumber} 
              onChange={(event) => setCertificationNumber(event.target.value)}
            />
          </Form.Group>
          <Button onClick={handleSubmit}>Search Archive</Button>
        </Form>
      </Jumbotron>

      {renderCertification()}

      {noResults ? (
        <div style={{ textAlign: 'center' }}>
          <p>Sorry, the certification number you entered could not be found.</p>
        </div> ) : undefined}
    </div>
  );
}

export default App;
